import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

function Logo() {
  return <img src="/logo.png" alt="Logo" loading="lazy" className="w-36 pb-2" />;
}

Logo.propTypes = {};

export default Logo;
