import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';
import Header from '../Header/Header';
import ResultsDisplay from '../ResultsDisplay/ResultsDisplay';
import { useGeolocation } from '../../hooks/useGeolocation';
import { findMeRequest } from '../../services/findMeService';
import { useSettings } from '../../context/SettingsContext';

function AppRetrieve() {
  const [searchParams] = useSearchParams();

  const referenceNumber = searchParams.get('ref');
  const apiKey = searchParams.get('key');

  const [currentStatus, setCurrentStatus] = useState('geolocating');
  const [errorMessage, setErrorMessage] = useState('');

  const [displayData, setDisplayData] = useState({
    address: null,
    latitude: null,
    longitude: null,
    accuracy: null
  });

  useEffect(() => {
    if (!apiKey) {
      setErrorMessage('"key" not specified in URL');
      setCurrentStatus('error');
    } else if (!referenceNumber) {
      setErrorMessage('"ref" not specified in URL');
      setCurrentStatus('error');
    } else {
      setCurrentStatus('geolocating');
      makeRequest();
    }

    return () => {
      // setErrorMessage('');
    };
  }, []);

  /*
   * Success FindMe API request callback
   * @parameters
   * @param {object} response - The response from the findMeRequest api
   */
  const handleSuccess = (response) => {
    if (response?.result) {
      setDisplayData({
        accuracy: response.accuracy,
        address: response.result.address.formatted_address,
        latitude: response.result.location.latitude,
        longitude: response.result.location.longitude
      });

      setErrorMessage('');
      setCurrentStatus('success');
    } else if (response?.success && response?.message && !response?.result) {
      setDisplayData({
        message: `${response.message} - The Reference Number has not been used to capture a location`
      });
      setErrorMessage('');
      setCurrentStatus('success');
    }
  };

  /*
   * Unsuccessful FindMe API request callback
   * @parameters
   * @param {object} error - The error returned from the findMeRequest api
   */
  const handleError = (error) => {
    if (error?.message) {
      setErrorMessage(`${error.message}`);
      setCurrentStatus('error');
    } else {
      setCurrentStatus('error');
    }
  };

  /*
   * Make a request to the findMeRequest api retrieve path
   */
  const makeRequest = () => {
    const parameters = {
      ref: referenceNumber,
      key: apiKey
    };

    findMeRequest(parameters, handleSuccess, handleError, '/retrieve');
  };

  return (
    <Container maxWidth="sm" className="flex h-screen flex-col bg-cover bg-center">
      <Header options="" logoIcon="" className="" />
      <ResultsDisplay
        path="retrieve"
        status={currentStatus}
        errorMessage={errorMessage}
        results={{ apiKey, referenceNumber, ...displayData }}
        options=""
      />
    </Container>
  );
}

AppRetrieve.propTypes = {};

export default AppRetrieve;
