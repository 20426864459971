import { createContext, useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const SettingsContext = createContext(null);
function SettingsProvider({ children }) {
  const [searchParams] = useSearchParams();

  const timeout = searchParams.get('timeout');
  const accuracy = searchParams.get('accuracy');

  /*
   * This is the configuration object of the project, it allows us to customize certain aspects of the display, functional parameters and theme colors
   * @attributes
   * @attribute {object} display - The customizable ui elements of the display
   * @attribute {object} parameters - The adjustable functional parameters of the app
   */

  /*
   * Notes:
   * MUI icons and button components are affected by the theme themeColours object
   */

  const initialSettings = {
    /*
     * The customizable ui elements of the display each path retrieve and location have configurable values
     * @parameters
     * @param {object} backgroundColour - The background colour to be used by the dislay card for each corresponding state
     * @param {object} headerText - The heading text to be used by the dislay card for each corresponding state
     * @param {object} subHeaderText - The sub heading text to be used by the dislay card for each corresponding state
     * @param {object} button - Render a button based on state
     * @param {object} buttonText - The button text to be used by the dislay card for each corresponding state
     */
    display: {
      location: {
        backgroundColour: {
          // please adjust colours in brackets, needed to use tailwind css to make it work, check rootClassName variable in ResultsComponent
          geolocating: 'bg-[#22D3EE]',
          waiting: 'bg-[#F87171]',
          success: 'bg-[#34D399]',
          found: 'bg-[#34D399]]',
          error: 'bg-[#F87171]'
        },
        headerText: {
          geolocating: 'Searching...',
          waiting: 'Waiting for response',
          success: 'Success!',
          found: 'Location Found',
          error: 'Error'
        },
        subHeaderText: {
          geolocating: "We're getting your current position. This might take up to a minute for the best accuracy.",
          waiting: 'Please be patient as while we confirm your location',
          found: `We've determined your location and are forwarding it to the appropriate contact center.`,
          success: `You have successfully shared your location with the relevant contact center.`,
          error: 'Oops, seems something is wrong.'
        },
        button: {
          geolocating: false,
          waiting: false,
          found: false,
          success: false,
          error: true
        },
        buttonUrl: {
          error: '',
          geolocating: '',
          waiting: '',
          found: '',
          success: 'https://28east.co.za'
        },
        buttonText: {
          error: 'Retry Geolocation',
          geolocating: '',
          waiting: '',
          found: '',
          success: 'Contact Us'
        }
      },
      retrieve: {
        backgroundColour: {
          // please adjust colours in brackets, needed to use tailwind css to make it work, check rootClassName variable in ResultsComponent
          geolocating: 'bg-[#22D3EE]',
          waiting: 'bg-[#F87171]',
          success: 'bg-[#34D399]',
          found: 'bg-[#34D399]]',
          error: 'bg-[#F87171]'
        },
        headerText: {
          geolocating: 'Searching...',
          waiting: 'Waiting for response',
          success: 'Success!',
          found: 'Location Found',
          error: 'Error'
        },
        subHeaderText: {
          geolocating: "We're getting your current position. This might take up to a minute for the best accuracy.",
          waiting: 'Please be patient as while we confirm your location',
          found: `We've determined your location and are forwarding it to the appropriate contact center.`,
          success: `You have successfully retrieved your location with the relevant contact center.`,
          error: 'Oops, seems something is wrong.'
        },
        button: {
          geolocating: false,
          waiting: false,
          found: false,
          success: false,
          error: true
        },
        buttonText: {
          error: 'Retry Retrieval',
          geolocating: '',
          waiting: '',
          found: '',
          success: ''
        }
      }
    },

    /*
     * The adjustable parameters of the app, url parameters override defaults
     * @parameters
     * @param {number} timeout(ms) - The maximum amount of time that the app should spend attempting to find a users location
     * @param {number} accuracy(m) - The minimum accuracy required to make a valid location request
     */
    parameters: {
      // eslint-disable-next-line radix
      timeout: parseFloat(timeout) || 120000,
      // eslint-disable-next-line radix
      accuracy: parseFloat(accuracy) || 200
    }
  };

  const [settings, setSettings] = useState(initialSettings);

  return <SettingsContext.Provider value={settings}>{children}</SettingsContext.Provider>;
}

export function useSettings() {
  return useContext(SettingsContext);
}

export default SettingsProvider;
