import { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Container, Button, Paper, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// eslint-disable-next-line no-restricted-imports
import TouchRipple from '@mui/material/ButtonBase/TouchRipple';
import axios from 'axios';
import styles from './ResultsDisplay.module.css';
import StatusHeading from '../StatusHeading';
import ResultListItem from './ResultListItem';
import { useSettings } from '../../context/SettingsContext';

function ResultsComponent({ path = 'location', status, errorMessage, results, className, clickHandler }) {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const rippleRef = useRef(null);
  const containerRef = useRef(null);

  const { display } = useSettings();

  const { buttonText, subHeaderText, backgroundColour, headerText, button, buttonUrl } = display[path];

  useEffect(() => {
    if (status === 'success') {
      setLoading(false);
      triggerRipple();
    } else if (status === 'error') {
      setHasError(true);
      setLoading(false);
    } else if (status === 'found') {
      setHasError(false);
      setLoading(false);
    } else setLoading(true);

    return () => {
      setLoading(true);
    };
  }, [status]);

  const triggerRipple = () => {
    const container = containerRef.current;
    const rect = container.getBoundingClientRect();

    rippleRef.current.start(
      {
        clientX: rect.left + rect.width / 2,
        clientY: rect.top + rect.height / 2
      },
      // when center is true, the ripple doesn't travel to the border of the container
      { center: false }
    );

    setTimeout(() => rippleRef.current.stop({}), 150);
  };

  const rootClassName = cn(styles.root, className, {
    [backgroundColour.success]: status === 'success' || status === 'found',
    [backgroundColour.geolocating]: status === 'waiting' || status === 'geolocating',
    [backgroundColour.error]: status === 'error'
  });

  const handleError = (error) => (
    <Typography className="pt-4 text-center">
      {error ||
        "We could not locate you. Please click on the link again and select 'allow' when prompted (If not already)."}
    </Typography>
  );
  const handleClick = async (e) => {
    setLoading(true);
    if (clickHandler) {
      clickHandler(e);
    }
  };

  const handleRetry = () => {
    setLoading(true);
    setHasError(false);
    window.location.reload(true);
  };

  const resultsArray = Object.keys(results).map((key) => (
    <ResultListItem className="" key={key} name={key} data={results[key]} />
  ));

  return (
    <Paper elevation={0} className={rootClassName} ref={containerRef}>
      <StatusHeading status={status} className="py-8" headerText={headerText?.[status]} />
      <Typography className="text-center font-bold"> {subHeaderText?.[status]} </Typography>

      {errorMessage && handleError(errorMessage)}
      <Container className="grow pt-4">{status === 'success' && resultsArray}</Container>

      {button?.error && hasError && (
        <LoadingButton
          className="rounded-2xl bg-red-600 hover:bg-red-400"
          size=""
          onClick={handleRetry}
          loading={loading}
          variant="contained"
        >
          {buttonText?.[status] || 'Retry Geolocation'}
        </LoadingButton>
      )}
      {button?.[status] === true && !hasError && (
        <Button
          className="rounded-2xl bg-green-600 hover:bg-green-400"
          size=""
          variant="contained"
          href={`${buttonUrl?.[status]}/?address=${results.address}&latitude=${results.latitude}&longitude=${results.longitude}&ref=${results.referenceNumber}`}
        >
          {buttonText?.[status] || ''}
        </Button>
      )}

      <TouchRipple ref={rippleRef} center={false} />
    </Paper>
  );
}

ResultsComponent.propTypes = {};

export default ResultsComponent;
