import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import cn from 'classnames';

function ResultListItem({ name, data, className }) {
  if (name === 'apiKey') return null;

  return (
    <Grid container className={cn(className, '')}>
      <Grid item>
        <Typography className="font-bold">{`${name.charAt(0).toUpperCase() + name.slice(1)}: `}</Typography>
      </Grid>
      <Grid item>
        <Typography className="pl-2">
          {name === 'accuracy' ? `${data.toFixed(2)} Meters` || 'Not Available' : data || 'Not Available'}
        </Typography>
      </Grid>
    </Grid>
  );
}

ResultListItem.propTypes = {};

export default ResultListItem;
